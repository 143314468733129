<template>
  <div>
    <h1>Briefing manche {{ currentRound.round_number }}</h1>

    <errors-viewer :errors="currentRound.configurationErrors"></errors-viewer>

    <collapse-panel lazy title="Présentation du staff" v-model="showStaffPanel" :visible="currentRound.round_number == 1">
      <collapse-panel lazy title="Juges" v-model="showStaff" depth="2">
        <detailed-staff-list :items="d_staff" />
        <b-row v-for="item in d_staff" :key="item.id">
          {{ item.name }}{{ item.isShadow ? " (ombre)" : ""}}
        </b-row>
      </collapse-panel>
      <collapse-panel lazy title="Ombres" v-model="showShadowStaff" depth="2" v-if="d_shadow_staff.length > 0">
        <detailed-staff-list :items="d_shadow_staff" />
      </collapse-panel>
    </collapse-panel>

    <collapse-panel lazy title="Représentants (juges & pilotes)" v-model="showDelegatesPanel">
      <form-field caption="Chef juge de la compétition">
        <b-form-input type="text" readonly :value="currentCompetition.CHIEF_JUDGE != null ? currentCompetition.CHIEF_JUDGE.name : ''" />
        <div class="error" v-if="currentCompetition.CHIEF_JUDGE == null">Le chef juge de la compétition n'a pas encore été défini !" </div>
      </form-field>
      <form-field caption="Chef juge pour la manche" :state="d_chief_judge == ''" invalidFeedback="Pas encore défini !">
        <b-form-input type="text" readonly :value="d_chief_judge"  />
        <div class="error" v-if="d_chief_judge == null || d_chief_judge == ''">Le chef juge de la manche n'a pas encore été défini !</div>
      </form-field>

      <form-field caption="Représentant des pilotes">
        <template #notice v-if="d_pilotsDelegate == null">
          Indiquez quel pilote s'est porté volontaire et a été désigné pour tenir le rôle de réprésentant des pilotes pour toute la durée de la compéition
        </template>
        <b-select v-model="d_pilotsDelegate" @change="pilotsDelegate_change" :disabled="currentRound.isBriefingCompleted">
          <option v-for="pilot in d_pilots" :key="pilot.id" :value="pilot.id">{{ pilot.name }}</option>
        </b-select>
      </form-field>    
    </collapse-panel>

    <collapse-panel lazy title="Format / Epreuves" v-model="showFormatPanel">
      <div v-if="currentRound.round_number==1">La compétition repose sur le règlement "<strong>{{ currentCompetition.REGULATION.name }}</strong>".</div>
      <div v-if="currentRound.round_number==1">Il est prévu de faire <strong>{{ currentCompetition.ROUNDS.length }}</strong> manche composées d'une ou plusieurs épreuves.</div>
      <div>Pour cette {{ currentRound.rank }} manche, il y aura :
         <ul>
          <li v-for="event in currentRound.EVENTS" :key="event.name + event.level">1 épreuve de type <strong>{{ event.EVENT.name }}</strong> ({{ event.LEVEL.name}})</li>
        </ul>
      </div>
<!--
      <div v-if="d_levels.length > 1">
        <p>Choisissez un niveau puis une cagégorie pour afficher les figures correspondantes</p>
        <b-tabs lazy vertical>
          <b-tab v-for="level in d_levels" :key="level.code" :title="level.name">
            <b-tabs lazy>
              <b-tab v-for="category in level.categories" :key="category.key">
                <template #title>
                  <icon-category v-if="true" variant="dark" v-model="category.code"/>
                </template>
                <event-compulsories
                >
              </b-tab>
            </b-tabs>            
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <p>Choisissez une catégorie pour afficher les figures correspondantes</p>
      </div>-->
    </collapse-panel>

    <collapse-panel v-if="d_levels_with_events_with_compulsories.length > 0" lazy title="Figures de précision">
      
        <div class="notice" v-if="d_levels_with_events_with_compulsories.length > 1">Choisissez un niveau puis l'épreuve et enfin une catégorie pour afficher les figures de précision qui seront à présenter lors de chaque épreuve en comportant</div>
        <div class="notice" v-else-if="d_levels_with_events_with_compulsories[0].eventsWithCompulsories.length > 1">Choisissez une épreuve puis une catégorie pour afficher les figures de précision qui seront à présenter lors de l'épreuve</div>
        <div class="notice" v-else-if="d_levels_with_events_with_compulsories[0].eventsWithCompulsories[0].categories.length > 1">Choisissez une catégorie pour afficher les figures de précision qui seront à présenter lors de l'épreuve</div>
        <levels :value="d_levels_with_events_with_compulsories" v-slot="levelSlotProps" hideSingleItemTitle>
            <events :value="levelSlotProps.level.eventsWithCompulsories" v-slot="eventSlotProps" hideSingleItemTitle>
              <!--<template name="single-item-title-postpend">&nbsp;-&nbsp;{{levelSlotProps.level.name}}</template>-->
              <categories iconSize="xsm" :value="eventSlotProps.event.categories" v-slot="categorySlotProps" hideSingleItemTitle>
                  <h5><!--<icon-category v-if="categorySlotProps.isSingle" :size="xsm" v-model="categorySlotProps.category.code"></icon-category>-->"{{ eventSlotProps.event.name}}" ({{ levelSlotProps.level.name}}) - {{ categorySlotProps.category.name }}</h5>
                <compulsories v-model="categorySlotProps.category.compulsories"></compulsories>
              </categories>
            </events>
        </levels>


    </collapse-panel>

    <collapse-panel lazy title="Ordres de passage" v-model="showCompetitorsOrderPanel">
      <div v-if="d_competitors_order_rule != null && d_competitors_order_rule != ''">
        <div>La règle retenue pour déterminer l'ordre de passage est "<strong>{{ d_competitors_order_rule }}</strong>".</div>

        <div v-if="d_levels.length > 1">
          <div class="notice">Choisissez un niveau puis une catégorie pour afficher les ordres de passage pour cette catégorie dans ce niveau</div>
          <levels v-model="d_levels" v-slot="levelSlotProps">
            <div v-if="levelSlotProps.level.categories.legnth > 1">
              <categories v-model="levelSlotProps.level.categories" v-slot="categorySlotProps" iconSize="xsm">
                <competitors v-model="categorySlotProps.category" includeLevelInTitle></competitors> 
              </categories>
            </div>
            <competitors v-else v-model="levelSlotProps.level.categories[0]" includeLevelInTitle>
            </competitors>
          </levels>
        </div>
        <div v-else>
          <div v-if="d_levels[0].categories.length > 1">
            <div class="notice">Choisissez une catégorie pour afficher les ordres de passage pour cette catégorie</div>
            <categories v-model="d_levels[0].categories" v-slot="categorySlotProps" icon-size="xsm">
              <competitors v-model="categorySlotProps.category">
              </competitors>
            </categories>
          </div>
          <competitors v-else v-model="d_levels[0].categories[0]">
          </competitors>
        </div>
      </div>
      <div v-else class="error">La règle à utiliser pour déterminer l'ordre de passage n'a pas encore été définie !</div>
    </collapse-panel>

    <collapse-panel lazy title="Présentation des terrains" v-model="showFieldsPanel" >
      <div v-if="d_fields.length > 1">
        <div class="notice">Si possible, indiquez sur quel terrain se dérouleront les différentes épreuves</div>
        <b-tabs lazy >
          <b-tab v-for="field in d_fields" :key="field.id" :title="'N° ' + field.field_number">
            <field-editor
              :value="field"
              showSummary
              :canSetHotState = "!currentRound.isBriefingCompleted"
              :isRemovable="false"
              @stageIn_change="fieldStageIn_change"
              @stageOut_change="fieldStageOut_change"
              @hotState_change="fieldHotState_change"
            />
          </b-tab>
        </b-tabs>
      </div>
      <div v-else-if="d_fields.length == 1">
        <field-editor
          :value="d_fields[0]"
          showSummary
          :canSetHotState="!currentRound.isBriefingCompleted"
          :isRemovable="false"
          @stageIn_change="fieldStageIn_change"
          @stageOut_change="fieldStageOut_change"
          @hotState_change="fieldHotState_change"
        />
      </div>
    </collapse-panel>

    <b-container fluid>
      <b-button :variant="currentCompetition.PILOTS_DELEGATE == null ? 'outline-primary' : 'primary'" style="width:100% !important;" @click="completeBriefing" v-if="currentRound.isReadyToBriefing && !currentRound.isBriefingCompleted" :disabled="currentCompetition.PILOTS_DELEGATE == null">TERMINER LE BRIEFING<br/>pour démarrer les épreuves de la manche</b-button>
    </b-container>
<!--
      <hr v-if="levels.length > 1"/>



      <h3 v-if="selectedLevel != null">Format : {{ selectedLevel.RULE.RULE.shortname}} </h3>
      <div v-if="selectedLevel.RULE.EVENTS.length > 1">
        <span>{{ selectedLevel.RULE.EVENTS.length}} épreuves</span>
        <div  v-for="event in selectedLevel.RULE.EVENTS" :key="event.id">
          <span v-if="selectedLevel.RULE.EVENTS.findIndex(e => e.id == event.id) > 0"> et </span>
          {{ event.EVENT.name }}
          <event-components-list v-model="event.EVENT" v-if="event.EVENT.componentsCount > 1"></event-components-list>
        </div>
      </div>
      <div v-else>
        <span>1 seule épreuve</span>
        <event-components-list v-model="selectedLevel.RULE.EVENTS[0].EVENT"></event-components-list>
      </div>

      <div v-if="categories.length > 1">
        <div class="explain">Choisissez une catégorie pour afficher ses détails</div>
        <b-form-radio-group
          button-variant="outline-primary"
          v-if="selectedLevel != null"
          v-model="selectedCategory"
          :options="categories"

          buttons
        >
        </b-form-radio-group>
      </div>

      <fieldset class="fieldset" v-if="selectedCategory != null">
        <div v-if="selectedLevel.RULE.hasCompulsories">
          <h4 >Figures de précision</h4>
          <div v-for="compulsory in compulsories" :key="compulsory.compulsory_id">
            <b-badge variant="info" pill>{{ compulsory.order }}</b-badge> <span>{{ compulsory.COMPULSORY.code }} - {{ compulsory.COMPULSORY.name }}</span>
          </div>
        </div>




         <h4>Ordre de passage</h4>
        <detailed-competitors-list
          :items="competitors"
          :showHeaders="false"
          :showOrder="true"
        >
        </detailed-competitors-list>
      </fieldset>

      <hr/>
      <h4>Terrain(s)</h4>
      <p>Vous pouvez dès à présent indiquer que {{ fields.length == 1 ? "le terrain" : "l'un ou l'autre des terrains"}} est "chaud" en cliquant sur le boutton correspondant ci-dessous.
        Vous pouvez également modifier les stage-in et stage-out définis par défaut.
      </p>
      <b-button v-for="field in fields" :key="field"
        :variant="field.isHot ? 'outline-danger' : 'outline-success'"
      >
      <field-editor :value="field"
        size="sm"
        :canSetHotState="true"
        :showSummary="true"
        :canSetStages="true"
        @hotState_change="fieldHotState_change"
        @stageIn_change="fieldStageIn_change"
        @stageOut_change="fieldStageOut_change"
      >

      </field-editor>
      </b-button>-->
       <!-- <icon-category size="sm"></icon-category>
        <icon-category size="sm" :isTwoLines="false"></icon-category>
        <icon-category></icon-category>
        <icon-category :isTwoLines="false"></icon-category>
        <icon-category size="lg" border-variant="danger" content-variant="warning"></icon-category>
        <icon-category size="lg" :isTwoLines="false" variant="success"></icon-category>
        <icon-category size="xl" :isTwoLines="false" competitor-type="pair" variant="success"></icon-category>
        <icon-category size="xl" :isTwoLines="false" competitor-type="team" variant="success"></icon-category> 

      <hr/>
      <h4>Grille des épreuves</h4>
      <events-launcher-dashboard>
      </events-launcher-dashboard>
-->
  </div>
</template>

<script>
import { RST_ROUND, RST_FIELD, RST_LEVEL, RST_COMPETITION_STAFF, RST_COMPETITOR, RST_EVENT, RST_EVENT_COMPETITOR } from "@/LocalDB";
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import FormField from '../../../components/Panels/FormField.vue';
import DetailedStaffList from '@/components/DetailedStaffList.vue';
import { CompetitorExecOrderRuleEnum } from '@/Constants';
import SynchroHelper from "@/services/helpers/synchroHelper";

/*import { RST_ROUND_COMPULSORY, RST_FIELD } from "@/LocalDB";
import RoundHeader from "@/components/RoundHeader";*/
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
//import DetailedCompetitorsList from '@/components/DetailedCompetitorsList.vue';
/*import EventComponentsList from '@/components/eventComponentsList.vue';
import EventsLauncherDashboard from '@/components/EventsLauncherDashboard.vue';*/
import FieldEditor from '@/components/fieldEditor.vue';
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
//import IconCategory from "@/components/IconCategory";
import {  RST_DELEGATE, RST_EVENT_COMPULSORY } from "../../../LocalDB";
//import EventCompulsories from '../components/EditWizard/EventCompulsories.vue';
import Levels from "../components/Briefing/Levels.vue";
import Events from "../components/Briefing/Events.vue";
import Categories from "../components/Briefing/Categories.vue";
import Competitors from "../components/Briefing/Competitors.vue";
import Compulsories from "../components/Briefing/Compulsories.vue";
//import IconCategory from "../../../components/IconCategory.vue";
import ErrorsViewer from "../components/ErrorsViewer.vue";

export default {
  components: { CollapsePanel, FormField, ErrorsViewer, /*IconCategory,*/ FieldEditor, DetailedStaffList, /*DetailedCompetitorsList,*/ Levels, Categories, Competitors, Events, Compulsories /*EventCompulsories*/ },
  data(){
    return {
      //d_competition_id: this.currentCompetition_id,
      //d_round_number: this.currentRound_number,

      showStaffPanel: true, showStaff: true, showShadowStaff: true,
      showDelegatesPanel: false,
      showFormatPanel: false,
      showCompulsoriesPanel: false,
      showCompetitorsOrderPanel: false,
      showFieldsPanel: false,

      d_pilotsDelegate: null,

      d_competitors_order_rule: "",

      d_chief_judge: null,
      d_staff: [],
      d_shadow_staff: [],
      d_judges: [],
      d_pilots: [],
      d_levels: [],
      d_fields: [],

      d_events_with_compulsories: [],
      d_levels_with_events_with_compulsories: [],

      //d_errors: [],
//      dSelectedLevel: null,
//      dSelectedCategory: null,
      mounting: false,
    };
  },

  computed:{
    ROUND(){ return this.currentRound; }
/*
    levels(){
      var ret = RoundsConfigurationHelper.ensureLevelsHavingEffectiveCompetitors(this.currentRound)
      .map(item => {
        item.text = item.LEVEL.name;
        item.value = item;
        return item;
      });
      return ret;
    },
    selectedLevel:{
      get(){ return (this.dSelectedLevel == null && this.levels.length == 1) ? this.levels[0] : this.dSelectedLevel },
      set(val){ this.dSelectedLevel = val; }
    },
    categories(){
      var ret = RoundsConfigurationHelper.ensureCategoriesHavingEffectiveCompetitors(this.selectedLevel)
      .map(item => {
        item.text = item.CATEGORY.name;
        item.value = item;
        return item;
      });
      return ret;
    },
    selectedCategory:{
      get(){ return (this.dSelectedCategory == null && this.categories.length == 1) ? this.categories[0] : this.dSelectedCategory},
      set(val){ this.dSelectedCategory = val; }
    },
    compulsories(){
      if(this.selectedLevel == null) return [];
      var ret = RST_ROUND_COMPULSORY.query()
                  .where("competition_id", this.selectedCategory.competition_id)
                  .where("round_number", this.selectedCategory.round_number)
                  .where("level", this.selectedCategory.level)
                  .where("category", this.selectedCategory.category)
                  .orderBy("order")
                  .get();
      return ret;
    },
    competitors(){
      if(this.selectedCategory == null) return [];

      var ret = this.selectedCategory.EFFECTIVE_COMPETITORS;
      ret = RoundsConfigurationHelper.sortCompetitorsForExecution(ret, this.currentRound.competitors_order_rule);
      return ret;
    },
    pilots(){
      var ret = RoundsConfigurationHelper.getPilotsDelegateCandidates(this.currentRound);
      return ret;
    },
    pilotsDelegate: {
      get(){ return null; },
      set(newValue){
        console.log("pilotsDelegate (set)", newValue);
      }
    },
    fields(){
      return RST_FIELD.query().where("competition_id", this.currentCompetition_id).get();
    }*/
  },
  methods:{
    async refresh()
    {
      this.d_round_number = this.currentRound_number;
      //console.log('refresh', this.currentCompetition_id, this.currentRound_number)
      await this.showRefresher();
      //var R = RST_ROUND.query().where("competition_id", this.currentCompetition_id).where("round_number", this.currentRound_number).first();
      
      //this.d_errors = R.configurationErrors; //console.log('R', R);
      var pilots = [];
      this.d_levels_with_events_with_compulsories = RST_LEVEL.query()
                        .where("competition_id", this.currentCompetition.id)
                        .orderBy(l => l.LEVEL.displayOrder)
                        .get()
                        .map(lvl => {
                          var events = this.currentRound._EVENTS.where(e => e.level == lvl.level).where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get().map(evt => {
                            console.log('evt', evt);
                            var categories = evt.LEVEL._CATEGORIES
                              .where(c => c.effective_competitors_count > 0)
                              .orderBy(c => c.CATEGORY.displayOrder)
                              .get()
                              .map(cat => {
                                var compulsories = RST_EVENT_COMPULSORY.query()
                                                    .where("competition_id", this.currentRound.competition_id)
                                                    .where("round_number", this.currentRound.round_number)
                                                    .where("level", lvl.level)
                                                    .where("category", cat.category)
                                                    .where("event", evt.event)
                                                    .get();

                                cat.compulsories = compulsories;
                                cat.name = cat.CATEGORY.name;
                                cat.code = cat.category;
                                return cat;
                              });
                            return { ...evt, categories: categories, name: evt.name };
                          });
//                          if(events.length > 0) this.d_levels_with_events_with_compulsories++;
                          return { ...lvl, ...lvl.LEVEL, eventsWithCompulsories: events };
                        })
                        .filter(lvl => {
                          return lvl.eventsWithCompulsories != null && lvl.eventsWithCompulsories.length > 0
                        });
      this.d_levels = RST_LEVEL.query()
                        .where("competition_id", this.currentCompetition_id)
                        .orderBy(l => l.LEVEL.displayOrder)
                        .get()
                        .map(lvl => {
                          var categories = lvl._CATEGORIES
                            .where(c => c.effective_competitors_count > 0)
                            .orderBy(c => c.CATEGORY.displayOrder)
                            .get()
                            .map(cat => {
                            var allCompetitors = RST_COMPETITOR.query()
                              .where("competition_id", this.currentCompetition_id)
                              .where("level", lvl.level)
                              .where("category", cat.category)
                              .where(c => !c.isWithdrawn && !c.isAbsent && !c.isForfeited)
                              .get()
                              .map(c => { 
                                if(cat.CATEGORY.isIndividual && pilots.findIndex(p => p.id == c.COMPETITOR.people_id) < 0){
                                  pilots.push(c.COMPETITOR.PEOPLE)
                                } else if(!cat.CATEGORY.isIndividual){
                                  c.MEMBERS.forEach(m => {
                                    if(pilots.findIndex(p => p.id == m.people_id) < 0){
                                      pilots.push(m.PEOPLE);
                                    }
                                  })
                                }
                                return { ...c, ...c.COMPETITOR, MEMBERS: c.COMPETITOR.MEMBERS }
                              });
                            allCompetitors = RoundsConfigurationHelper.sortCompetitorsForExecution(allCompetitors,this.currentRound, lvl.level, cat.category);
                            this.d_competitors_order_rule = CompetitorExecOrderRuleEnum.getDisplayName(this.currentRound.competitors_order_rule);
                            /*switch(this.currentRound.competitors_order_rule){
                                case CompetitorExecOrderRuleEnum.Registration:
                                    this.d_competitors_order_rule = "Ordre d'inscription";
                                    //allCompetitors.sort((a,b) => this.compare(a.subscription_order , b.subscription_order) );
                                    break;
                                case CompetitorExecOrderRuleEnum.Alea:
                                    this.d_competitors_order_rule = "Ordre aléatoire";
                                    //allCompetitors.sort((a,b) => this.compare(a.random_order, b.random_order));
                                    break;
                                case CompetitorExecOrderRuleEnum.Alpha:
                                    this.d_competitors_order_rule = "Ordre alphabétique";
                                    //allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'asc'));
                                    break;
                                case CompetitorExecOrderRuleEnum.AlphaReverse:
                                    this.d_competitors_order_rule = "Ordre alphabétique inversé";
                                    //allCompetitors.sort((a,b) => this.compare(a.name, b.name, 'desc'));
                                    break;
                                case CompetitorExecOrderRuleEnum.PrevRoundReverse:
                                    this.d_competitors_order_rule = "Ordre inverse du tour précédent";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse + "' à implémenter");
                                    break;
                                case CompetitorExecOrderRuleEnum.Rank:
                                    this.d_competitors_order_rule = "Classement provisoire";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
                                    break;
                                case CompetitorExecOrderRuleEnum.RankReverse:
                                    this.d_competitors_order_rule = "Classement provisoire inversé";
                                    //console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
                                    break;
                            }*/
                            return { ...cat, ...cat.CATEGORY, competitors: allCompetitors, levelName: lvl.name };
                          }); 
/*                          var events = this.currentRound._EVENTS.where(e => e.level == lvl.level).where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get().map(evt => {
                            console.log('evt', evt);
                            var categories = evt.LEVEL._CATEGORIES.orderBy(c => c.CATEGORY.displayOrder)
                              .get()
                              .map(cat => {
                                var compulsories = RST_EVENT_COMPULSORY.query()
                                                    .where("competition_id", this.currentRound.competition_id)
                                                    .where("round_number", this.currentRound.round_number)
                                                    .where("level", lvl.level)
                                                    .where("category", cat.category)
                                                    .where("event", evt.event)
                                                    .get();

                                cat.compulsories = compulsories;
                                return cat;
                              });
                            return { ...evt, categories: categories };
                          });
                          if(events.length > 0) this.d_levels_with_events_with_compulsories++;*/
                          return { ...lvl, ...lvl.LEVEL, categories: categories/*, eventsWithCompulsories: events*/ };
                        });
                        console.log("d_levels", this.d_levels, this.d_levels_with_events_with_compulsories, this.d_errorq);
      //console.log('levels', this.d_levels);
      pilots.sort((a,b) => a.name.localeCompare(b.name));
      this.d_pilots = pilots;

      this.d_fields = RST_FIELD.query().where("competition_id", this.currentCompetition_id).orderBy("field_number").get();
      this.d_staff = RST_COMPETITION_STAFF.query().where("competition_id", this.currentCompetition.id).where(s => s.round_number == null).orderBy(s => s.name).get().map(s => {return { ...s, PEOPLE: s.PEOPLE, name: s.PEOPLE.name,  CAPABILITIES: s.CAPABILITIES }});
      //console.log("d_staff", this.d_staff)
      this.d_shadow_staff = RST_COMPETITION_STAFF.query().where("competition_id", this.currentCompetition_id).where(s => s.round_number == null && s.isShadow).orderBy(s => s.name).get();
      //console.log("d_shadows_staff", this.d_shadow_staff)
      
//      var pilotsIndiv = R.COMPETITION._COMPETITORS.where(p => !p.isForfeited && !p.isAbsent && !p.isWithdrawn && p.CATEGORY.isIndividual).get().map(s => s.PEOPLE);
//      var pilotsInTeam = R.COMPETITION._COMPETITORS.where(p=> !p.isForfeited && !p.isAbsent && !p.isWithdrawn && !p.CATEGORY.isIndividual)
//      this.d_chief_judge = R.CHIEF_JUDGE != null ? R.CHIEF_JUDGE.name : '';
      this.d_chief_judge = this.currentRound.CHIEF_JUDGE != null ? this.currentRound.CHIEF_JUDGE.name : '';

      this.d_events_with_compulsories = this.currentRound._EVENTS.where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get();

      var delegate = RST_DELEGATE.query().where('competition_id', this.currentCompetition.id).where('delegate_type', 'C').first();
      this.d_pilotsDelegate = (delegate == null) ? null : delegate.people_id;

      this.hideRefresher();
    },
    async pilotsDelegate_change(newVal){
      if(this.mounting) return;
      await this.showSaver();
      var ok = true;
      if(this.d_pilotsDelegate != null)
        ok = CompetitionsConfigurationHelper.removeDelegateAsync(this.currentCompetition.id, this.d_pilotsDelegate);
      if(ok)
        ok = CompetitionsConfigurationHelper.addDelegateAsync(this.currentCompetition.id, newVal);
      this.toastAsyncSaved(ok, "Délégué de pilote mis à jour");
      this.hideSaver();
    },
 
    async fieldStageIn_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldStageInAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async fieldStageOut_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldStageOutAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async fieldHotState_change(field_number, newValue){
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.setFieldHotStateAsync(this.currentCompetition_id, field_number, newValue);
        this.toastAsyncSaved(result, "Entrée du terrain modifiée");
      }catch(error)
      {
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async completeBriefing(){
      var operationIds = [];
      await this.showSaver();
      console.log('completeBriefing', this.d_levels);
      this.d_levels.forEach(lvl => {
        lvl.categories.forEach(cat => {
          var o = 0;
          cat.competitors.forEach(comp => {
            o++;
            console.log("currentRound.EVENTS", this.currentRound.EVENTS);
            this.currentRound.EVENTS.forEach(ref_evt => {
              var evt = RST_EVENT.query()
                          .where("competition_id", this.currentCompetition_id)
                          .where("round_number", this.currentRound_number)
                          .where("level", lvl.level)
                          .where("category", cat.category)
                          .where("event", ref_evt.event)
                          .first();
              if(evt == null)
              {
                var newId = this.newGuid();
                evt = new RST_EVENT();
                evt.$id = newId
                evt.id = newId;
                evt.competition_id = this.currentCompetition_id;
                evt.round_number = this.currentRound_number;
                evt.level = lvl.level;
                evt.category = cat.category;
                evt.event = ref_evt.event;
                RST_EVENT.insert({data: evt});
                operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT", null, evt));
              }
              var existingCompetitor = evt._COMPETITORS.where("competitor_id", comp.competitor_id).first();
              if(existingCompetitor == null){
                newId = this.newGuid();
                existingCompetitor = new RST_EVENT_COMPETITOR();
                existingCompetitor.$id = newId;
                existingCompetitor.id = newId;
                existingCompetitor.competition_id = evt.competition_id;
                existingCompetitor.competitor_id = comp.competitor_id;
                existingCompetitor.level = evt.level;
                existingCompetitor.category = evt.category;
                existingCompetitor.round_number = evt.round_number;
                existingCompetitor.event = evt.event;
                existingCompetitor.predicted_order = o;
                existingCompetitor.order = o;
                existingCompetitor.executionStatus = RST_EVENT_COMPETITOR.ExecutionStatus_Waiting; // W=waiting, R=running, C=completed
                existingCompetitor.isAbsent = comp.isAbsent;
                existingCompetitor.isForfeited = comp.isForfeited;
                //existingCompetitor.isFederal = comp.isFederal;
                RST_EVENT_COMPETITOR.insert({data: existingCompetitor});
                console.log('Insert competititor', existingCompetitor.name, existingCompetitor.predicted_order);
               operationIds.push(SynchroHelper.LogOperation("INSERT", "RST_EVENT_COMPETITOR", null, existingCompetitor));
              } else {
                var data = {
                  predicted_order: o,
                  order: o,
                  effective_order: 0,
                  executionStatus: RST_EVENT_COMPETITOR.ExecutionStatus_Waiting,
                  isAbsent: comp.isAbsent,
                  isForfeited: comp.isForfeited,
                  //isFederal: comp.isFederal,
                }
                RST_EVENT_COMPETITOR.update({ where: existingCompetitor.id, data: data });
                console.log('Updating RST_EVENT_COMPETITOR', existingCompetitor.name, existingCompetitor.predicted_order);
                operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_EVENT_COMPETITOR", existingCompetitor.id, data, "PREPARE_FOR_RUN" ));
              }
            });
          });
        });
      });
      var roundChanges = {
        isBriefingStarted: true,
        isBriefingCompleted: true,
        date: this.currentRound.date || new Date().toISOString(),
      }
      RST_ROUND.update({ where: this.currentRound.id, data: roundChanges});
      operationIds.push(SynchroHelper.LogOperation("UPDATE", "RST_ROUND", this.currentRound.id, roundChanges, "BRIEFING_STATUSES"))
      if(operationIds.length > 0)
      {
        var result = await SynchroHelper.TryToSynchronizeAsync(operationIds);
        this.toastAsyncSaved(result,"Les épreuves ont été préparées et peuvent maintenant être réalisées.");
        this.hideSaver();
        this.$router.push({name: 'CompetitionEditOrRun'});
      }
    },
  },
  async mounted(){
    this.d_pilotsDelegate = this.currentCompetition.PILOTS_DELEGATE != null ? this.currentCompetition.PILOTS_DELEGATE.people_id : null;
    this.mounting = true;
    this.$hideLoader();
    await this.refresh();
    this.mounting = false;
  }
}
</script>

<style scoped>
  label.btn{ margin-left: 5px; margin-right: 5px;}
  a.nav-link.active{ background-color: var(--ffvlOrangePicto) !important;}
  a.nav-link { padding: 0.1rem !important;}

  .error{ font-size: 1.1rem; color: var(--ffvlDanger); font-weight: bold; font-style: italic; display: block;}
</style>
