<template>
    <b-tabs v-if="value.length > 1">
        <b-tab v-for="category in value" :key="category.key">
            <template #title>
                <icon-category v-if="true" variant="dark" v-model="category.code" :size="iconSize"/>
            </template>
            <slot :category="category" :isSingle="false">
            </slot>
        </b-tab>
    </b-tabs>
    <div v-else-if="value.length == 1">
        <div v-show="!hideSingleItemTitle">
            <slot name="single-item-title">
                <h5>{{value[0].name}}</h5>
            </slot>
        </div>
        <slot :category="value[0]" :isSingle="true">
        </slot>
    </div>
</template>

<script>
import IconCategory  from '../../../../components/IconCategory.vue';
export default {
    components:{ IconCategory },
    props: { value: { type:Array, required: true }, iconSize: { type:String, default:''}, hideSingleItemTitle: { type: Boolean, default:false}}
}
</script>
