<template>
    <div v-if="value.length > 1">
        <CollapsePanel v-for="event in value" :key="event.name" :title="event.name">
            <slot :event="event" :isSingle="false"></slot>
        </CollapsePanel>
    </div>
    <div v-else>
        <div v-show="!hideSingleItemTitle">
            <slot name="single-item-title">
                <h5>{{value[0].name}}</h5>
            </slot>
        </div>
        <slot :event="value[0]" :isSingle="true"></slot>
    </div>
</template>

<script>
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

export default {
    components: { CollapsePanel },
    props: { value: { type: Array}, hideSingleItemTitle: { type:Boolean, default: false}}
}
</script>
