<template>
    <ul>
        <li v-for="compulsory in value" :key="compulsory.code">{{ compulsory.code}} - {{ compulsory.name }}</li>
    </ul>
</template>

<script>
export default {
    props: { value: { type: Array, required:true }}
}
</script>
