<template>
    <div>
        <h5>{{ value.name }}{{ includeLevelInTitle ? " - " + value.levelName : ''}}</h5>
        <detailed-competitors-list
            :items="value.competitors"
            :showHeaders="false"
            :showOrder="true"
        >
        </detailed-competitors-list>
    </div>
</template>

<script>
import DetailedCompetitorsList from '@/components/DetailedCompetitorsList.vue';

export default {
    components: { DetailedCompetitorsList },
    props: { value: { type: Object, required: true }, includeLevelInTitle: { type:Boolean, default: false}}
}
</script>
