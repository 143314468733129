<template>
    <div>
        <fieldset class="fieldset danger" v-if="elements.length > 0">
            <legend><slot name="legend">A corriger...</slot></legend>
            <ul>
                <li v-for="element in elements" :key="element.KEY">
                {{ element.FR }}
                </li>
            </ul>
        </fieldset>
    </div>
</template>

<script>
export default{
    props:{
        errors: { type:Array }
    },
    computed:{
        elements(){
            if(this.errors == null) return [];
            return this.errors;
        }
    }
}
</script>
