<template>
  <div v-if="value.length > 1">
    <slot name="prepend" :isSingle="false"></slot>
    <b-tabs lazy vertical >
      <b-tab v-for="level in value" :key="level.code" :title="level.name">
        <slot :level="level" :isSingle="false"></slot>
      </b-tab>
    </b-tabs>
  </div>
  <div v-else-if="value.length == 1">
    <slot name="prepend" :isSingle="true"></slot>
      <div v-show="!hideSingleItemTitle">
          <slot name="single-item-title">
              <h5>{{value[0].name}}</h5>
          </slot>
      </div>
      <slot :level="value[0]" :isSingle="true">
      </slot>
  </div>
</template>

<script>
export default {
  props: { value: { type:Array, required: true }, hideSingleItemTitle: { type: Boolean, default:false}}
}
</script>
